import { AllHTMLAttributes, FC, useState } from "react";
import joinClassNames from "classnames";

import EmptyCircledIcon from "icons/empty-circled.svg?react";

import { BaseProps } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends AllHTMLAttributes<HTMLImageElement>, BaseProps {}

const Icon: FC<Props> = ({
  src,
  altImage: DefaultImage = EmptyCircledIcon,
  mark,
  className,
  ...props
}) => {
  const [isError, setIsError] = useState(false);
  const imageClasses = joinClassNames(
    classes.sizePresets,
    classes.icon,
    className,
  );

  let image: JSX.Element;
  if (isError || !src) {
    image = (
      <DefaultImage data-size={props["data-size"]} className={imageClasses} />
    );
  } else {
    image = (
      <img
        src={src}
        onError={() => {
          setIsError(true);
        }}
        className={imageClasses}
        {...props}
      />
    );
  }

  return mark ? (
    <div className={classes.markWrapper}>
      {image}
      {mark}
    </div>
  ) : (
    image
  );
};

export default Icon;
