import { CSSProperties } from "react";
import isObject from "lodash/isObject";
import snakeCase from "lodash/snakeCase";
import { find } from "lodash";

export const getCSSVariables = <K extends string>(
  variables: Partial<Record<K, string | number>>,
) => {
  const cssVariables: Record<string, string | number> = {};
  for (const name in variables) {
    cssVariables[`--${snakeCase(name).replaceAll("_", "-")}`] = variables[name];
  }

  return cssVariables as CSSProperties;
};

export const getLabelValueType = (option: ListOption): LabelValue => {
  if (isObject(option)) {
    return option;
  }

  return {
    label: option,
    value: option,
  };
};

export function getDefault<T extends { default: boolean }>(
  items: T[],
): T | undefined;

export function getDefault<T extends { default: boolean }>(
  items: Record<string, T>,
): T | undefined;

export function getDefault(items) {
  return find(items, item => item.default);
}
