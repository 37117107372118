import { FC, HTMLAttributes, ReactNode } from "react";
import joinClassNames from "classnames";

import { Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  size?: Sizes;
  children: ReactNode;
  isCircled?: boolean;
}

const Mark: FC<Props> = ({
  size = "sm",
  children,
  isCircled = true,
  ...restProps
}) => (
  <div
    data-size={size}
    data-circled={isCircled}
    className={joinClassNames(classes.sizePresets, classes.wrapper)}
    {...restProps}
  >
    {children}
  </div>
);

export default Mark;
