import { createSlice } from "@reduxjs/toolkit";

import type { ExtraInitialState } from "./duck/types";

import reducers from "./reducers";
import extraReducers from "./extraReducers";

export const sellSlice = createSlice({
  name: "sell",
  initialState: {
    quotes: [],
    quotesError: null,
    isLoadingQuotes: true,
    isLoadedQuotes: false,
  } as ExtraInitialState,
  reducers,
  extraReducers,
});

export default sellSlice;
