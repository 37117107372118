import HappyFaceIcon from "emojis/happy-face.svg?react";
import UnhappyFaceIcon from "emojis/unhappy-face.svg?react";
import MoonPayLogoIcon from "icons/moonpay-logo.svg?react";
import BanxaLogoIcon from "icons/banxa-logo.svg?react";
import RampLogoIcon from "icons/ramp-logo.svg?react";
import StripeLogoIcon from "icons/stripe-logo.svg?react";
import TopperLogoIcon from "icons/topper-logo.svg?react";
import TransakLogoIcon from "icons/transak-logo.svg?react";
import RevolutLogoIcon from "icons/revolut-logo.svg?react";
import MercuryoLogoIcon from "icons/mercuryo-logo.svg?react";

import { FeedbackValues } from "./types";

export const FEEDBACK_OPTIONS = [
  {
    value: FeedbackValues.satisfied,
    icon: HappyFaceIcon,
  },
  {
    value: FeedbackValues.unhappy,
    icon: UnhappyFaceIcon,
  },
];

export const PROVIDER_LOGOS = {
  MoonPay: MoonPayLogoIcon,
  Banxa: BanxaLogoIcon,
  Ramp: RampLogoIcon,
  Stripe: StripeLogoIcon,
  Topper: TopperLogoIcon,
  Transak: TransakLogoIcon,
  Revolut: RevolutLogoIcon,
  Mercuryo: MercuryoLogoIcon,
};

export const MAX_COMMENT_LENGTH = 512;
