import { createSlice } from "@reduxjs/toolkit";

import type { ExtraInitialState } from "./duck/types";

import reducers from "./reducers";
import extraReducers from "./extraReducers";

export const buySlice = createSlice({
  name: "buy",
  initialState: {
    coinIds: [],
    isLoadedCoins: false,
    quotes: [],
    quotesError: null,
    isLoadingQuotes: true,
    isLoadedQuotes: false,
    insight: null,
    isLoadedInsight: false,
    coinId: null,
    isLoadedCoin: false,
    loadCoinsError: null,
    providers: [],
    isLoadedProviders: false,
  } as ExtraInitialState,
  reducers,
  extraReducers,
});

export default buySlice;
