import { authInstance, instance } from "./instances/coindisco";

export interface SearchOption {
  cryptocurrency: Coin["id"];
  currency: Currency["id"];
  cryptocurrencyAmount: string;
  network: Network["id"];
}

export interface RetrieveSellLinkOptions {
  currency: number;
  cryptocurrency: number;
  cryptocurrencyAmount: string;
  network: number;
  service: number;
}

export interface RetrieveBuyLinkResult {
  link: string;
  transactionId: string;
}

export interface SearchQuotesResult {
  status: "in_progress" | "completed";
  searchId: string;
  data: SellProvider[];
}

const sellCryptoAPI = new (class {
  public async searchByOptions(options: SearchOption) {
    const { data } = await instance.post<{ searchId: string }>(
      "service-integration/v2/search-sell-options/",
      options,
    );

    return data.searchId;
  }

  public async searchProvidersById(searchId: string) {
    const { data } = await instance.get<SearchQuotesResult>(
      `service-integration/v2/search-sell-options/${searchId}/`,
    );

    return data;
  }

  public async retrieveSellLink(values: RetrieveSellLinkOptions) {
    const { data } = await authInstance.post<RetrieveBuyLinkResult>(
      "service-integration/retrieve-sell-link/",
      values,
    );

    return data;
  }
})();

export default sellCryptoAPI;
