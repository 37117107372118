import { type PayloadAction } from "@reduxjs/toolkit";

import { SearchQuotesResult } from "api/sell";

import { ReducerState } from "./duck/types";
import { getGroupedProviders } from "./duck/selectors";

const reducers = {
  setProviders: (
    state: ReducerState,
    { payload }: PayloadAction<SearchQuotesResult>,
  ) => {
    state.quotes = getGroupedProviders(payload.data);
  },
};

export default reducers;
