import { authInstance, instance } from "./instances/coindisco";

export interface SearchOption {
  service?: Provider["slugName"][];
  nokyc?: boolean;
  data?: {
    region: Region["id"];
    paymentMethod: PaymentMethod["id"];
    cryptocurrency: Coin["id"];
    currency: Currency["id"];
    baseCurrency: Currency["id"];
    network: Network["id"];
    currencyAmount: string;
  }[];
}

export interface RetrieveBuyLink {
  region: number;
  cryptocurrency: number;
  currency: number;
  network: number;
  service: number;
  currencyAmount: number;
  paymentMethod: number;
  walletAddress: string;
}

export interface RetrieveBuyLinkResult {
  link: string;
  transactionId: string;
}

export interface SearchQuotesResult {
  status: "in_progress" | "completed";
  searchId: string;
  data: BuyProvider[];
}

const buyCryptoAPI = new (class {
  public async searchByOptions(options: SearchOption) {
    const { data } = await instance.post<{ searchId: string }>(
      "service-integration/v2/search-buy-options/",
      options,
    );

    return data.searchId;
  }

  public async searchProvidersById(searchId: string) {
    const { data } = await instance.get<SearchQuotesResult>(
      `service-integration/search-buy-options/${searchId}/`,
    );

    return data;
  }

  public async retrieveBuyLink(values: RetrieveBuyLink) {
    const { data } = await authInstance.post<RetrieveBuyLinkResult>(
      "service-integration/retrieve-buy-link/",
      values,
    );

    return data;
  }
})();

export default buyCryptoAPI;
