import buyCryptoPreview from "meta/buy-crypto.png";
import defaultPreview from "meta/default.png";
import coinPreview from "meta/coin.png";

export const ROOT_META = {
  description:
    "Buy crypto: Bitcoin (BTC), Ethereum (ETH), Ripple (XRP), Solana (SOL), Cardano (ADA), Tether (USDT), and over 300 altcoins with credit/debit card, bank transfers, Apple Pay and Google Pay on Coindisco today",
  title: "Coindisco - the best way to buy crypto",
  ogImage: defaultPreview,
};

export const BUY_CRYPTO_META = {
  ogImage: buyCryptoPreview,
};

export const SELL_CRYPTO_META = {
  // replace with sell preview
  ogImage: buyCryptoPreview,
};

export const COIN_META = {
  ogImage: coinPreview,
};
