import useDispatch from "hooks/redux/use-dispatch";

import { accountLoadScenario } from "operations/scenaries";

import { currenciesThunks } from "store/currencies";
import { coinsThunks } from "store/coins";

export const useConnect = () => {
  const dispatch = useDispatch();

  return {
    loadCurrencies: () => dispatch(currenciesThunks.loadCurrencies()),
    loadPrices: () => dispatch(coinsThunks.loadPrices()),
    accountLoadScenario: () => dispatch(accountLoadScenario()),
  };
};
