import { useMemo } from "react";

import useResizeWindow from "hooks/use-resize-window";

import { SIZE } from "constants/styles";

const useWindowSizes = (): WindowSizes => {
  const [windowSize] = useResizeWindow();

  return useMemo(
    () => ({
      isMobileWidth: SIZE.getIsMobileWidth(windowSize),
      isMobileLargeWidth: SIZE.getIsMobileLargeWidth(windowSize),
      isMobileMediumWidth: SIZE.getIsMobileMediumWidth(windowSize),
      isMobileSmallWidth: SIZE.getIsMobileSmallWidth(windowSize),
      isTabletSmallWidth: SIZE.getIsTabletSmallWidth(windowSize),
      isTabletWidth: SIZE.getIsTabletWidth(windowSize),
      isTabletLargeWidth: SIZE.getIsTabletLargeWidth(windowSize),
      isDesktopSmallWidth: SIZE.getIsDesktopSmallWidth(windowSize),
      isDesktopMediumWidth: SIZE.getIsDesktopMediumWidth(windowSize),
      isDesktopLargeWidth: SIZE.getIsDesktopLargeWidth(windowSize),
      isDesktopWidth: SIZE.getIsDesktopWidth(windowSize),
      size: windowSize,
    }),
    [windowSize],
  );
};

export default useWindowSizes;
