import { BUY_BUTTON_ONLY_SCREEN } from "./constants";

export const getListCellSchema = windowSizes => {
  const {
    isDesktopWidth,
    isTabletSmallWidth,
    isMobileMediumWidth,
    isMobileSmallWidth,
    isMobileWidth,
    size,
  } = windowSizes;

  const isTabletSmaller = isTabletSmallWidth || isMobileWidth;
  let comboWidth = 158;
  if (isTabletSmaller) {
    comboWidth = 142;
  }
  if (isMobileSmallWidth) {
    comboWidth = 108;
  }
  if (size.width < BUY_BUTTON_ONLY_SCREEN) {
    comboWidth = 58;
  }

  const schema = [
    { width: 32 },
    { flex: isMobileSmallWidth ? 1.3 : 1.5 },
    { flex: 1 },
    { flex: 1 },
    { flex: 0.75 },
    { flex: 0.75 },
    { width: comboWidth },
    { width: isTabletSmaller ? 48 : 56 },
  ];

  if (!isDesktopWidth) {
    // hide 7d column
    schema[5] = null;
  }

  if (isMobileWidth || isTabletSmallWidth) {
    // hide market cap column
    schema[3] = null;
  }

  if (isMobileWidth) {
    // hide 24h column
    schema[4] = null;
  }

  if (isMobileSmallWidth || isMobileMediumWidth) {
    schema[7] = null;
  }

  return schema;
};
