import coinsAPI from "api/coins";
import { insightsAPI } from "api/insights";

import servicesAPI from "api/services";

import { createAsyncServerThunk } from "operations/redux";

export const loadCoinServer = createAsyncServerThunk<
  BaseCoin | Coin,
  { id: Coin["letterId"]; isLight?: boolean }
>(
  "buy/loadCoin",
  ({ id, isLight = true }) =>
    isLight ? coinsAPI.fetchLightCoin(id) : coinsAPI.fetchCoin(id),
  {
    selectResult: (state, { id }) => {
      const {
        coins: { entities },
        buy: { coinId, isLoadedCoin, isLoadedCoins },
      } = state;
      const coin = entities[coinId];

      if (isLoadedCoin && coin.letterId === id) {
        return coin;
      }

      if (isLoadedCoins) {
        return entities[id];
      }
    },
  },
);

export const loadInsightServer = createAsyncServerThunk<Insight, Insight["id"]>(
  "buy/loadInsightServer",
  id => insightsAPI.fetchInsight(id),
  {
    selectResult({ buy }, insightId) {
      if (buy.isLoadedInsight && buy.insight?.id === insightId) {
        return buy.insight;
      }
    },
  },
);

export const loadProvidersServer = createAsyncServerThunk<Provider[]>(
  "buy/loadProvidersServer",
  () => servicesAPI.fetchProviders(),
  {
    selectResult({
      buy: { providers: allProviders, isLoadedProviders: isLoadedAllProviders },
    }) {
      if (isLoadedAllProviders) {
        return allProviders;
      }
    },
  },
);
