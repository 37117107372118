import {
  autoBatchEnhancer,
  configureStore,
  createListenerMiddleware,
  type ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";

import { coinsSlice } from "./coins";
import { accountSlice } from "./account";
import { paymentSlice } from "./payment";
import { currenciesSlice } from "./currencies";
import { addressesSlice } from "./addresses";
import { storageSlice, subscribe } from "./storage";
import { buySlice } from "./buy";
import { sellSlice } from "./sell";
import { insightsSlice } from "./insights";
import { regionsSlice } from "./regions";

interface Options {
  preloadedState?: object;
  devTools?: boolean;
}

export const configure = ({
  devTools = false,
  preloadedState = {},
}: Options) => {
  const listenerMiddleware = createListenerMiddleware();
  const store = configureStore({
    preloadedState,
    reducer: {
      // service reducers
      [storageSlice.name]: storageSlice.reducer,
      // rest reducers
      [coinsSlice.name]: coinsSlice.reducer,
      [accountSlice.name]: accountSlice.reducer,
      [paymentSlice.name]: paymentSlice.reducer,
      [currenciesSlice.name]: currenciesSlice.reducer,
      [buySlice.name]: buySlice.reducer,
      [sellSlice.name]: sellSlice.reducer,
      [addressesSlice.name]: addressesSlice.reducer,
      [insightsSlice.name]: insightsSlice.reducer,
      [regionsSlice.name]: regionsSlice.reducer,
    },
    devTools,
    enhancers: existingEnhancers =>
      existingEnhancers.concat(autoBatchEnhancer()),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  });

  subscribe(store);

  type Store = typeof store;
  type State = ReturnType<Store["getState"]>;
  type Dispatch = Store["dispatch"];

  return {
    store,
    watcher: listenerMiddleware as ListenerMiddlewareInstance<State, Dispatch>,
  };
};
