import { AxiosError, AxiosRequestConfig } from "axios";
import { addDays } from "date-fns";

import storages from "operations/storage";
import { getCookies } from "operations/cookie";
import { NetworkError, ResponseError } from "operations/error";
import { processPaymentMethod } from "operations/payment";

import { instance, authInstance } from "./instances/coindisco";

type HandledResponseError = AxiosError<ResponseError> & {
  config: AxiosRequestConfig<ResponseError> & { _retry?: boolean };
};

abstract class TokenManager {
  private readonly UNAUTHORIZED = 401;
  private readonly STORAGE_KEY = "auth_token";
  protected readonly TOKEN_HEADER_KEY = "x-token";

  constructor() {
    authInstance.interceptors.request.use(request => {
      if (this.token) {
        request.headers["Authorization"] = `Token ${this.token}`;
      }

      request.headers["Content-Type"] = "application/json";

      return request;
    });

    authInstance.interceptors.response.use(
      response => {
        const xToken = response.headers[this.TOKEN_HEADER_KEY];
        if (xToken) {
          this.setToken(xToken);
        }

        return response;
      },
      (error: HandledResponseError) => {
        const { response } = error;
        const isUnauthorize = response?.status === this.UNAUTHORIZED;
        if (isUnauthorize && this.token) {
          this.resetToken();
        }

        return Promise.reject(new NetworkError(error));
      },
    );
  }

  protected get token(): string | null {
    return getCookies().get(this.STORAGE_KEY) || null;
  }

  protected setToken(token: string) {
    getCookies().set(this.STORAGE_KEY, token, {
      expires: addDays(new Date(), 100),
      path: "/",
    });
  }

  protected resetToken() {
    getCookies().delete(this.STORAGE_KEY);
  }
}

interface AccountDTO {
  account: Account;
  addresses: Address[];
  paymentMethods: PaymentMethod[];
}

interface CodeTime {
  email: string;
  time: number;
  isCanceled: boolean;
}

export interface VerifyAuthCodeBody {
  email: string;
  code: string;
  currency?: Currency["id"];
  favorites?: Coin["id"][];
  addresses?: {
    address: string;
    addressName: string;
    network: Network["id"];
  }[];
}

const accountAPI = new (class extends TokenManager {
  public readonly RESEND_CODE_DELAY = 60 * 1000;

  public codeTimes = new (class {
    private readonly STORAGE_KEY = "send_code_data";

    public get value(): CodeTime | null {
      return storages.sessionStorage.get<CodeTime>(this.STORAGE_KEY);
    }

    public insert(email: string) {
      storages.sessionStorage.set<CodeTime>(this.STORAGE_KEY, {
        time: Date.now(),
        email,
        isCanceled: false,
      });
    }

    public change(values: Partial<Omit<CodeTime, "email">>) {
      const value = this.value;
      if (!value) {
        return;
      }

      storages.sessionStorage.set<CodeTime>(this.STORAGE_KEY, {
        ...value,
        ...values,
      });
    }

    public clear() {
      storages.sessionStorage.remove(this.STORAGE_KEY);
    }
  })();

  public async sendAuthCode(email: string) {
    const { data } = await instance.post<{ email: string }>(
      "custom-auth/auth/classic/email/",
      {
        email,
      },
    );

    this.codeTimes.insert(email);

    return data;
  }

  public async verifyAuthCode(values: VerifyAuthCodeBody): Promise<AccountDTO> {
    const {
      headers,
      data: { paymentMethods = [], addresses = [], ...account },
    } = await instance.post("custom-auth/auth/classic/email/code/", values);
    this.setToken(headers[this.TOKEN_HEADER_KEY]);
    this.codeTimes.clear();

    return {
      account,
      addresses,
      paymentMethods: paymentMethods.map(processPaymentMethod),
    };
  }

  public async logout() {
    await authInstance.delete("custom-auth/auth/logout/");
    this.resetToken();
  }

  public async getAccount() {
    if (!this.token) {
      throw new Error("Authentication credentials were not provided.");
    }

    const { data } = await authInstance.get<Account>("custom-auth/users/me/");

    return data;
  }

  public async updateAccount(values: Partial<UpdateAccount>) {
    const { data } = await authInstance.patch<Account>(
      "custom-auth/users/me/",
      values,
    );

    return data;
  }
})();

export default accountAPI;
