import { ReactNode, useId, useImperativeHandle, useRef, useState } from "react";
import joinClassNames from "classnames";

import CircledWarning20Icon from "icons/circled-warning20.svg?react";

import {
  TextareaRefProps,
  InputRefProps,
  InputElementTypes,
} from "../../duck/types";
import { REPLACER_MAP } from "./duck/constants";

import classes from "./styles/classes.module.scss";

function BlankInput(props: InputRefProps): ReactNode;
function BlankInput(props: TextareaRefProps): ReactNode;

function BlankInput({
  isBlock,
  as,
  size = "sm",
  before,
  after,
  under,
  fusion,
  className,
  label,
  hint,
  classNames = {},
  innerRef,
  error,
  onChange,
  ...props
}: InputRefProps & TextareaRefProps): ReactNode {
  const inputRef = useRef(null);
  const id = useId();
  const [isFocused, setIsFocused] = useState(
    Boolean(!props.disabled && props.autoFocus),
  );

  const isError = Boolean(error);
  const isUnder = Boolean(under);

  useImperativeHandle(innerRef, () => inputRef.current);

  const InputComponent = (as as InputElementTypes) ?? "input";
  const replace = REPLACER_MAP[props.inputMode];

  const input = (
    <InputComponent
      ref={inputRef}
      id={id}
      data-errored={isError}
      data-under={isUnder}
      className={joinClassNames(classes.blank, classes.input, classNames.input)}
      onChange={
        props.pattern
          ? event => {
              if (event.target.validity.patternMismatch) {
                return;
              }

              if (replace) {
                event.target.value = replace(event.target.value);
              }

              onChange(event);
            }
          : onChange
      }
      {...props}
      onFocus={event => {
        setIsFocused(true);
        props.onFocus?.(event);
      }}
      onBlur={event => {
        setIsFocused(false);
        props.onBlur?.(event);
      }}
    />
  );

  const contentInput = (
    <div
      data-focused={isFocused}
      data-block={isBlock}
      data-errored={isError}
      data-readonly={props.readOnly}
      className={joinClassNames(
        classes.focusWrapper,
        className,
        classes.fusionInput,
      )}
      onMouseDown={event => {
        if (event.target !== inputRef.current) {
          event.preventDefault();
        }

        if (!isFocused) {
          inputRef.current.focus();
        }
      }}
    >
      {before}
      {isUnder ? (
        <div className={classes.underWrapper}>
          {input}
          <div className={classes.under}>{under}</div>
        </div>
      ) : (
        input
      )}
      {after}
    </div>
  );

  return (
    <div
      data-type="input"
      data-size={size}
      data-under={isUnder}
      data-fusion={Boolean(fusion)}
      className={joinClassNames(classes.sizePresets, classNames.wrapper)}
    >
      {label && (
        <label
          htmlFor={id}
          onMouseDown={isFocused ? event => event.preventDefault() : undefined}
          className={joinClassNames(classes.label, classNames.label)}
        >
          {label}
        </label>
      )}
      {fusion ? (
        <div className={classes.fusionWrapper}>
          {contentInput}
          <div className={classes.fusion}>{fusion}</div>
        </div>
      ) : (
        contentInput
      )}
      {hint && (
        <label
          htmlFor={id}
          className={joinClassNames(classes.hint, classNames.hint)}
        >
          {hint}
        </label>
      )}
      {error && (
        <div
          className={joinClassNames(
            classes.errorMessage,
            classNames.errorMessage,
          )}
        >
          <CircledWarning20Icon />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}

export default BlankInput;
