import { FC } from "react";
import joinClassNames from "classnames";

import Icon from "./Icon";
import { BaseProps, Sizes } from "./duck/types";

import classes from "./styles/classes.module.scss";

interface Props extends BaseProps {
  size?: Sizes;
  className?: string;
}

const CoinIcon: FC<Props> = ({
  src: icon,
  size = "sm",
  altImage,
  mark,
  className,
}) => (
  <Icon
    src={icon}
    data-size={size}
    altImage={altImage}
    mark={mark}
    className={joinClassNames(classes.coinIcon, className)}
  />
);

export default CoinIcon;
