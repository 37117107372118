import { ChangeEvent } from "react";
import { type EntityId } from "@reduxjs/toolkit";

import MenuVerticalIcon from "icons/menu-vertical.svg?react";
import EditIcon from "icons/edit.svg?react";
import Trash24Icon from "icons/trash24.svg?react";

import Button from "basics/button";
import Checkbox, { Tick } from "basics/checkbox";
import Loader from "basics/loader";
import Dropdown, { Item } from "basics/dropdown";

import CircledIcon, { PaymentMethodIcon } from "components/icon";

import { PaymentMethodStatuses } from "types/payment";

import { openEditPaymentMethodModal } from "modules/payment-method-modals";

import { useConnect } from "./duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props {
  id: EntityId;
}

const PaymentMethodCard = ({ id }: Props) => {
  const {
    updatePaymentMethod,
    removePaymentMethod,
    paymentMethod,
    paymentMethodStatus,
    isDisabledDeleteMethod,
  } = useConnect(id);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    updatePaymentMethod({ ...paymentMethod, isActive: e.target.checked });
  };

  const isActivityChanging =
    paymentMethodStatus === PaymentMethodStatuses.activityChanging;

  const isEditingOrRemoving =
    paymentMethodStatus === PaymentMethodStatuses.editing ||
    paymentMethodStatus === PaymentMethodStatuses.removing;

  const isDisabled = isActivityChanging || isEditingOrRemoving;

  return (
    <Checkbox
      themeName="colorful"
      className={classes.checkbox}
      classNames={{
        input: classes.checkboxInput,
        label: classes.checkboxLabel,
      }}
      isLoading={isActivityChanging}
      name={String(id)}
      checked={paymentMethod.isActive}
      onChange={onChange}
      disabled={isDisabled}
    >
      <div className={classes.headerWrapper}>
        <CircledIcon
          className={classes.circledIcon}
          src={
            paymentMethod.region.parentRegion?.icon || paymentMethod.region.icon
          }
        />
        <p className={classes.countryName}>
          {paymentMethod.region.parentRegion
            ? `${paymentMethod.region.parentRegion.name}, ${paymentMethod.region.name}`
            : paymentMethod.region.name}
        </p>
        <Tick className={classes.tick} />
        <Dropdown
          onClick={event => event.preventDefault()}
          placement="bottom_end"
          menuComponent={
            <>
              <Item
                onClick={() => {
                  openEditPaymentMethodModal(id);
                }}
              >
                <EditIcon />
                Edit
              </Item>
              <Item
                disabled={isDisabledDeleteMethod}
                onClick={() => {
                  removePaymentMethod(id);
                }}
              >
                <Trash24Icon className={classes.trash24Icon} />
                Delete
              </Item>
            </>
          }
        >
          <Button
            size="xs"
            isIconOnly
            themeName="ghost"
            className={classes.settingsButton}
            disabled={isDisabled}
          >
            {isEditingOrRemoving ? (
              <Loader
                themeName={
                  paymentMethodStatus === PaymentMethodStatuses.editing
                    ? "default"
                    : "destructive"
                }
                size="sm"
              />
            ) : (
              <MenuVerticalIcon className={classes.menuVerticalIcon} />
            )}
          </Button>
        </Dropdown>
      </div>
      <div className={classes.paymentWrapper}>
        <p className={classes.paymentText}>{paymentMethod.currency.name}</p>
        <div className={classes.paymentOptionsWrapper}>
          {paymentMethod.categories.map(
            ({ name: category, icon: categoryIcon, index }) => (
              <div className={classes.option} key={index}>
                <PaymentMethodIcon size="xs" src={categoryIcon} />
                <p className={classes.paymentText}>{category}</p>
              </div>
            ),
          )}
        </div>
      </div>
    </Checkbox>
  );
};

export default PaymentMethodCard;
