import {
  HTMLAttributes,
  InputHTMLAttributes,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import joinClassNames from "classnames";

import {
  focusHandler,
  inputHandler,
  keyDownHandler,
  pasteHandler,
} from "./duck/operations";
import { CodeInputTypes } from "./duck/types";
import { PREPARES } from "./duck/constants";

import classes from "./styles/classes.module.scss";

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  length?: number;
  value?: string;
  type?: CodeInputTypes;
  onChange?: (value: string) => void;
  onFilled?: (value: string) => void;
  wrapperProps?: Omit<HTMLAttributes<HTMLDivElement>, "className">;
  classNames?: Partial<{
    input: string;
  }>;
}

const CodeInput = forwardRef<HTMLInputElement[], Props>(
  (
    {
      length = 6,
      value = "",
      autoFocus = false,
      type = "number",
      onChange,
      onFilled,
      classNames = {},
      wrapperProps,
      className,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      name,
      ...restProps
    },
    ref,
  ) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const inputsRef = useRef<HTMLInputElement[]>([]);

    const context = {
      onChange,
      inputs: inputsRef.current,
      prepareValue: PREPARES[type],
      onFilled,
    };

    useImperativeHandle(ref, () => inputsRef.current);

    return (
      <div
        {...wrapperProps}
        className={joinClassNames(classes.codeInputWrapper, className)}
        onAnimationStart={event => {
          setIsAnimating(true);
          wrapperProps?.onAnimationStart?.(event);
        }}
        onAnimationEnd={event => {
          setIsAnimating(false);
          wrapperProps?.onAnimationEnd?.(event);
        }}
      >
        {Array.from({ length }).map((_event, index) => (
          <input
            {...restProps}
            className={joinClassNames(classes.codeInput, classNames.input)}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onAnimationEnd={event => event.stopPropagation()}
            onPaste={event => pasteHandler(event, index, context)}
            maxLength={1}
            ref={elem => {
              inputsRef.current[index] = elem!;
            }}
            autoComplete="one-time-code"
            onFocus={event => focusHandler(event, index, context)}
            onInput={event => inputHandler(event, index, context)}
            autoFocus={!index && autoFocus}
            value={value[index]?.trim() || ""}
            type={type === "password" ? "password" : type}
            onKeyDown={event => keyDownHandler(event, index, context)}
            placeholder="_"
            readOnly={isAnimating || restProps.readOnly}
          />
        ))}
      </div>
    );
  },
);

export default CodeInput;
