import { FC, ReactNode, useMemo } from "react";
import { isUndefined } from "lodash";
import joinClassNames from "classnames";

import { AmountTypes, ClickFuncParams } from "./duck/types";
import { getAmountData, getAmountTypesBool } from "./duck/selectors";
import { BaseProps } from "../duck/types";

import classes from "../styles/classes.module.scss";

interface Props extends BaseProps {
  type?: AmountTypes;
  tokenCode?: string | ReactNode; // ReactNode use only <span> with styles
  customPrefix?: string;
  isCalculate?: boolean;
  isZeros?: boolean;
  onClick?: (params: ClickFuncParams) => void;
  classNames?: Partial<{
    zeros: string;
  }>;
}

const Amount: FC<Props> = ({
  value,
  tokenCode,
  precision,
  symbol,
  exchangeRate,
  type = "default",
  customPrefix,
  isCalculate = true,
  isZeros,
  className,
  onClick,
  classNames = {},
}) => {
  const { isSummary, isInteger } = getAmountTypesBool(type);
  const innerIsZeros = isUndefined(isZeros) ? !Boolean(tokenCode) : isZeros;

  const amountForFormat =
    isCalculate && isSummary && !tokenCode ? value.times(exchangeRate) : value;

  const {
    amountPart,
    amountString,
    bigAmount,
    postfix,
    prefixLess,
    prefixSymbol,
    zerosPart,
  } = useMemo(
    () =>
      getAmountData({
        precision,
        symbol,
        tokenCode,
        type,
        value: amountForFormat,
        isZeros: innerIsZeros,
      }),
    [amountForFormat, precision, symbol, tokenCode, type, innerIsZeros],
  );

  if (isInteger && !Number.isInteger(amountForFormat.toNumber())) {
    return "Value is not integer!";
  }

  return (
    <span
      className={joinClassNames(classes.wrapper, className)}
      onClick={onClick && (() => onClick({ amountString, bigAmount }))}
    >
      {prefixLess}
      <span dir="ltr">{customPrefix || prefixSymbol}</span>
      {amountPart}
      <span className={classNames.zeros}>{zerosPart}</span>
      {postfix && <> {postfix}</>}
    </span>
  );
};

export default Amount;
