import sellAPI, {
  RetrieveBuyLinkResult,
  RetrieveSellLinkOptions,
  SearchOption,
  SearchQuotesResult,
} from "api/sell";

import { TimeMilliseconds } from "constants/time";

import { sleep } from "operations/async";

import { buyConstants } from "store/buy";

import { sellSlice } from "./sell";
import { createAppAsyncThunk } from "../duck/types";
import { MAX_SEARCH_REQUEST_COUNT } from "./duck/constants";

export const searchQuotes = createAppAsyncThunk<
  string,
  {
    searchId?: string;
    options: SearchOption;
    updateSearch(searchId: string): void;
    isPrimeLoad?: boolean;
  }
>(
  "sell/searchQuotes",
  async (
    { options, searchId: initialSearchId, updateSearch, isPrimeLoad = false },
    { dispatch, signal },
  ) => {
    let searchId = initialSearchId || buyConstants.DEFAULT_SEARCH_ID;
    let result: SearchQuotesResult | null = null;
    if (searchId !== buyConstants.DEFAULT_SEARCH_ID) {
      try {
        result = await sellAPI.searchProvidersById(searchId);

        dispatch(sellSlice.actions.setProviders(result));
      } catch (error) {
        if (error.message === "Invalid search_id") {
          searchId = buyConstants.DEFAULT_SEARCH_ID;
          updateSearch(searchId);
        } else {
          throw error;
        }
      }
    }

    if (searchId === buyConstants.DEFAULT_SEARCH_ID) {
      searchId = await sellAPI.searchByOptions(options);
    }

    updateSearch(searchId);

    let count = 0;
    while (!signal.aborted && result?.status !== "completed") {
      if (++count > MAX_SEARCH_REQUEST_COUNT) {
        throw new Error("Invalid timeout");
      }

      await sleep(TimeMilliseconds.second * 2);
      if (signal.aborted) {
        break;
      }

      result = await sellAPI.searchProvidersById(searchId);

      if (isPrimeLoad || result.status === "completed") {
        dispatch(sellSlice.actions.setProviders(result));
      }
    }

    return searchId;
  },
);

export const retrieveSellLink = createAppAsyncThunk<
  RetrieveBuyLinkResult,
  RetrieveSellLinkOptions
>("sell/retrieveSellLink", payload => sellAPI.retrieveSellLink(payload));
