import { FC, PropsWithChildren, HTMLProps, useId } from "react";
import joinClassNames from "classnames";

import { Themes } from "./duck/types";

import classes from "./styles/classes.module.scss";
import { CheckboxContext } from "./duck/constants";

interface Props extends HTMLProps<HTMLInputElement> {
  className?: string;
  themeName?: Themes;
  isIndeterminate?: boolean;
  isLoading?: boolean;
  classNames?: Partial<{
    input: string;
    label: string;
  }>;
}

const Checkbox: FC<PropsWithChildren<Props>> = ({
  className,
  children,
  isLoading,
  themeName = "primary",
  classNames = {},
  isIndeterminate = false,
  checked = false,
  ...props
}) => {
  const id = useId();

  return (
    <div
      className={joinClassNames(
        classes.wrapper,
        {
          [classes.loading]: isLoading,
        },
        classes.themePresets,
        className,
      )}
      data-theme={themeName}
    >
      <input
        className={joinClassNames(classes.input, classNames.input)}
        id={id}
        type="checkbox"
        checked={checked}
        {...props}
      />
      <label
        className={joinClassNames(classes.label, classNames.label)}
        data-indeterminate={isIndeterminate}
        htmlFor={id}
      >
        <CheckboxContext.Provider value={{ checked, isIndeterminate }}>
          {children}
        </CheckboxContext.Provider>
      </label>
    </div>
  );
};

export default Checkbox;
