import { useEffect } from "react";

import useSelector from "hooks/redux/use-selector";

import { openFeedbackModal } from "modules/feedback-modal";

const FeedbackModalSync = () => {
  const transactionInfo = useSelector(state => state.storage.transactionInfo);

  useEffect(() => {
    if (transactionInfo) {
      openFeedbackModal(transactionInfo);
    }
  }, []);

  useEffect(() => {
    if (!transactionInfo) {
      return;
    }

    const callback = () => {
      openFeedbackModal(transactionInfo);
    };

    window.addEventListener("focus", callback);

    return () => {
      window.removeEventListener("focus", callback);
    };
  }, [transactionInfo]);

  return null;
};

export default FeedbackModalSync;
