import { openAlert } from "components/dialog";

import { SellCryptoBuilder } from "./duck/types";
import { retrieveSellLink, searchQuotes } from "./thunks";

const extraReducers = (builder: SellCryptoBuilder) => {
  //#region search

  builder
    .addCase(searchQuotes.pending, (state, action) => {
      state.isLoadingQuotes = true;
      state.quotesError = null;

      if (action.meta.arg.isPrimeLoad) {
        state.quotes = [];
        state.isLoadedQuotes = false;
      }
    })
    .addCase(searchQuotes.fulfilled, state => {
      state.isLoadingQuotes = false;
      state.isLoadedQuotes = true;
    })
    .addCase(searchQuotes.rejected, (state, { error }) => {
      if (error.name === "AbortError") {
        return;
      }

      state.isLoadingQuotes = false;
      state.isLoadedQuotes = true;

      if (state.quotes.length) {
        openAlert({
          type: "warning",
          message: "Some quotes may not have been loaded",
        });
      } else {
        state.quotesError = error.message;
      }
    });

  //#endregion

  //#region retrieveSellLink

  builder
    .addCase(retrieveSellLink.fulfilled, state => {
      state.quotes = [];
    })
    .addCase(retrieveSellLink.rejected, () => {
      openAlert({
        message: "An error occurred while generating the sell link",
      });
    });

  //#endregion
};

export default extraReducers;
